export default {
    home: 'home',
    about: 'about',
    quote: {
        required_fields: 'Fields marked with an asterisk (*) are mandatory.',
        confirm: "Your request for a quote has been successfully submitted! Our team will review it and get in touch with you as soon as possible to organize your trip!",
        submitAnother: "Submit Another Quote",
        questionsTitle: "You have questions?",
        questionsDescription: "If any questions arise in the meantime, don't hesitate to contact our team!",
        destination: "Destination",
        selectDestination: "Select a Destination",
        orTxt: 'or',
        otherDestinationPH: "Specify Another Destination",
        servicesCenter: "School Service Center",
        selectSC: "Select a Center",
        school: "School Name",
        organizerName: "Organizer Name",
        organizerEmail: "Organizer Email Address",
        organizerPhone: "Organizer Phone Number",
        plannedDeparture: "Departure Date",
        plannedReturn: "Return Date",
        plannedDepartureTime: "Departure Time",
        plannedReturnTime: "Return Time",
        studentsNumber: "Number of Students",
        chaperonsNumber: "Number of Chaperones",
        withTourGuide: "Presence of a Tour Guide",
        yes: "Yes",
        no: "No",
        insurances: "Insurance",
        descriptionTitle: "More Information",
        descriptionHelp: "Use this box to provide us with more details about your trip. Do you want to visit more than one city? How many nights do you wish to spend in each? Would you like to include meals? If so, which ones? What activities would you like to include in your itinerary?",
        chooseFile: "Select a File",
        noFile: "No File Selected",
        clientSource: "Where did you hear about us?",
        submit: "Submit Quote"
    },
    nav: {
        'parent': "Parent",
        'organizers': "Organizers"
    },
    footer: {
        'toll_free': "Toll-Free",
        'phone': "Phone",
        'newsletter_confirm': "Your newsletter subscription has been received! Watch your inbox for the next news!",
        'newsletterEmail': "Email Address",
        'rights': "All rights reserved.",
        'policy': "Privacy policy",
        'devBy': "Website developed by"
    },
    trips: {
        'type': {
            'plane': 'Plane',
            'bus': 'Bus',
            'all': 'All Destinations'
        },
        'noResult': 'No result for your search',
        'noResultHelp': 'Please adjust your search filters to show some results.',
        'seeMore': "See more destinations"
    },
    trip: {
        'goBack': 'Back to Destinations',
        'description': "Trip Description",
        'destination': "Destination",
        'days': "Days",
        'meals': "Meals",
        'options': "Customize your package by adding these activities to your itinerary",
        'quote': "Get a Quote Online",
        'itinerary': "Itinerary",
        'othersTitle': "Other Trips That May Interest You",
        'othersDescription': "Browse our travel collection for inspiration. You will find itineraries that you can customize according to your needs, interests, and budget.",
        'othersMore': "Explore All Destinations"
    }
}
