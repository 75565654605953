<template>
  <footer class="footer" id="contact">
    <section class="footer__section">
      <h2 class="footer__title"> {{ footer.title }} </h2>

      <div class="footer__links">
        <div class="footer__links-list">
          <b class="footer__links-list-title">{{ footer.coordinatesText }}</b>

          <a :href="footer.gmapLink" target="_blank" class="footer__links-list-item">{{ footer.address }}</a>

          <div class="footer__links-list-item">
            <p><a :href="`tel:${footer.phoneTollfree}`">{{ $t('footer.toll_free') }}: {{ footer.phoneTollfree }}</a></p>
            <p><a :href="`tel:${footer.phone}`">{{ $t('footer.phone') }}: {{ footer.phone }}</a></p>
          </div>

          <a :href="`mailto:${footer.email}`" class="footer__links-list-item -email">{{ footer.email }}  <Icon id="arrow"/></a>

          <div class="footer__socials">
            <a :href="footer.facebook" target="_blank" aria-label="facebook"><Icon id="facebook"/></a>
            <a :href="footer.instagram" target="_blank" aria-label="instagram"><Icon id="instagram"/></a>
          </div>
        </div>

        <div class="footer__links-list">
          <b class="footer__links-list-title">{{ footer.linksText }}</b>
          <a v-for="link in footer.links" :key="link" :href="link.link" class="footer__links-list-item">{{link.text}}</a>
        </div>
      </div>


    </section>

    <section class="footer__section">
      <h3 class="footer__subtitle">{{ footer.newsletterText }}</h3>

      <transition name="fade" mode="out-in">
        <div v-if="newsletterSent" class="footer__newsletter-confirm">
          <div class="soumission__confirm-check -sm">
            <Icon id="checkmark"/>
          </div>
          <p>{{ $t('footer.newsletter_confirm') }}</p>
        </div>

        <form class="footer__newsletter" @submit.prevent="submitNewsletter" v-else>
          <input type="email" class="footer__newsletter-input" :placeholder="$t('footer.newsletterEmail')" v-model="newsletterEmail"/>
          <button class="button -right" >
            {{ footer.newsletterBtn }}
            <div class="button__icon">
              <Icon id="arrow"/>
            </div>
          </button>
        </form>
      </transition>


      <div>
        <a href="https://www.manuvie.ca/particuliers.html" target="_blank"><img src="/svg/manuvie.svg" class="footer__manuvie" alt="manuvie"/></a>
        <small>{{ footer.manuvieText }}</small>
      </div>
    </section>
  </footer>

  <div class="footer__notes">
    <div>
      © 2023 Voyages A+. {{ $t('footer.rights') }} <span class="footer__notes-spacer">|</span> <LangRouterLink :to="{name: 'politique'}">{{ $t('footer.policy') }}</LangRouterLink>
    </div>

    <div>
      {{ $t('footer.devBy') }} <a href="https://boitebeet.com/" target="_blank" class="footer__notes-beet"> Beet </a>
    </div>
  </div>
</template>

<script>
import http from "@/extensions/http";
import LangRouterLink from '@/components/global/LangRouterLink.vue'

export default {
  name: "SiteFooter",
  components: {LangRouterLink},
  data() {
    return {
      newsletterEmail: '',
      newsletterSent: false
    }
  },
  computed: {
    footer() {
      return this.$beet.options.footer || {}
    }
  },
  methods: {
    submitNewsletter() {
      http.post('/newsletter', {email: this.newsletterEmail}).then(() => {
        this.newsletterSent = true
      }).catch(err => {
        console.log(err)
      })
    },
    goToCms() {
      window.open(process.env.VUE_APP_API_URL.replace('/api', ''), '_self')
    }
  }
}
</script>
