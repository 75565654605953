export default {
    gdpr: {
        banner: {
            title: 'Notre utilisation des témoins (cookies)',
            description: 'Nous utilisons des témoins (cookies) afin d’améliorer votre expérience de navigation et personnaliser le contenu de ce site internet. En cliquant sur Accepter, vous nous permettez d’améliorer la façon dont le site est construit.',
            accept: 'Accepter',
            settings: 'Modifier les préférences'
        },

        settings: {
            title: 'Notre utilisation des témoins (cookies)',
            description: 'Nous utilisons des témoins (cookies) pour faire fonctionner notre site. Nous utilisons aussi des témoins d\'analyse pour nous aider à l\'améliorer au fil du temps. Nous ne définirons pas de témoins à moins que vous ne les activiez. L\'utilisation de cet outil installera un témoin sur votre appareil pour mémoriser vos préférences. Pour plus d\'informations sur les témoins que nous utilisons, consultez notre page Énoncé de confidentialité.',
            essentials: {
                title: 'Témoins essentiels',
                description: 'Les témoins essentiels activent des fonctionnalités de base telles que la sécurité, la gestion du réseau et l\'accessibilité. Vous pouvez les désactiver en modifiant les paramètres de votre navigateur, mais cela peut affecter le fonctionnement du site Web.'
            },
            analytics: {
                title: 'Fichiers de témoins d’analyse',
                description: 'Nous aimerions définir des fichiers témoins Google Analytics pour nous aider à améliorer notre site Web en collectant et en rapportant des informations sur la façon dont vous l\'utilisez. Les fichiers témoins collectent des informations de manière complètement anonyme. Pour plus d\'informations sur le fonctionnement de ces fichiers témoins, veuillez consulter notre page Énoncé de confidentialité.',
            },
            save: 'Enregistrer et fermer'
        },


    }
}

