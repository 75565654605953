
export default {
    gdpr: {
        banner: {
            title: 'Our use of cookies',
            description: 'We use cookies to make this site work and to help us make improvements. Clicking accept will enable us to set recommended analytics cookies.',
            accept: 'ACCEPT COOKIES',
            settings: 'CHANGE COOKIES SETTINGS'
        },

        settings: {
            title: 'Our use of cookies',
            description: 'We use essential cookies to make our site work. We\'d also like to set optional analytics cookies to help us improve it. We won\'t set optional cookies unless you enable them. Using this tool will set a cookie on your device to remember your preferences. For more detailed information about the cookies we use, see our Privacy & Cookies page.',
            essentials: {
                title: 'Essential cookies',
                description: 'Essential cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.',
            },
            analytics: {
                title: 'Analytics cookies',
                description: 'We\'d like to set Google Analytics cookies to help us to improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone. For more information on how these cookies work, please see our Privacy & Cookies page.',
            },
            save: 'Save and close'
        },
    }
}
