<template>
  <RouterLink :to="route" :exact="exact">
    <slot/>
  </RouterLink>
</template>

<script>
export default {
  name: "LangRouterLink",
  props: {
    to: {
      type: Object,
      required: true
    },
    exact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    route() {
      const copy = Object.assign({}, this.to)
      if(copy.name.includes(':')) {
        copy.name = copy.name.substr(0, copy.name.indexOf(':'))
      }
      if(this.$i18n.locale !== this.$defaultLang) {
        copy.name += `:${this.$i18n.locale}`
      }
      return copy
    }
  }
}
</script>
