<template>
  <div class="navbar" :class="{'-open': isOpen}">
    <div class="navbar__row">
      <section class="navbar__section">
        <LangRouterLink :to="{name: 'home'}" class="navbar__logo">
          <img src="/svg/logo.svg" alt="voyage A+"/>
        </LangRouterLink>
        <div class="navbar__links" v-if="mq.mdPlus">
          <LangRouterLink :to="{name:'about'}" class="navbar__link"> {{ menu.nav.about }} </LangRouterLink>
          <LangRouterLink :to="{name:'voyages'}" class="navbar__link" :class="{'-active' : $route.name === 'voyage'}"> {{ menu.nav.destinations }} </LangRouterLink>
          <LangRouterLink :to="{name:'conseils'}" class="navbar__link"> {{ menu.nav.advices }} </LangRouterLink>
        </div>
      </section>

      <section class="navbar__section">
        <button class="button -light" @click="goToCms" v-if="mq.lgPlus">
          <div class="button__icon-nav">
            <Icon id="user" />
          </div>
          {{ $t('nav.parent')}} <span>|</span> {{ $t('nav.organizers')}}
        </button>

        <LangRouterLink :to="{name: 'soumission'}" class="button" v-if="mq.mdPlus">
          <div class="button__icon-nav">
            <img src="/svg/soumission.svg" alt="soumission"/>
          </div>
          {{ menu.nav.quote }}
        </LangRouterLink>

        <button class="button -light -center" @click="switchLang" v-if="mq.mdPlus">
          {{ langSwitchLabel }}
        </button>

        <button class="navbar__burger" @click="isOpen = !isOpen" aria-label="menu">
          <transition name="fade" duration="250" mode="out-in">
            <Icon :id="isOpen ? 'burger-close' : 'burger'" :key="`open-${isOpen}`"/>
          </transition>
        </button>
      </section>
    </div>

    <div class="navbar__content" v-if="isOpen">
      <div class="navbar__content-section">
        <LangRouterLink :to="{name:'about'}" class="navbar__content-link"> {{ menu.nav.about }} </LangRouterLink>

        <LangRouterLink :to="{name:'voyages'}" class="navbar__content-link"> {{ menu.nav.destinations }} </LangRouterLink>

        <LangRouterLink :to="{name:'conseils'}" class="navbar__content-link"> {{ menu.nav.advices }} </LangRouterLink>

        <button class="button -light -full" @click="goToCms">
          <div class="button__icon-nav">
            <Icon id="user" />
          </div>
          {{ $t('nav.parent')}} <span>|</span> {{ $t('nav.organizers')}}
        </button>

        <LangRouterLink :to="{name: 'soumission'}" class="button -full" >
          <div class="button__icon-nav">
            <img src="/svg/soumission.svg"/>
          </div>
          {{ menu.nav.quote }}
        </LangRouterLink>

        <button class="button -light" @click="switchLang">
          {{ langSwitchLabel }}
        </button>
      </div>

      <div class="navbar__content-section -info">
        <b class="footer__links-list-title">{{footer.coordinates_text}}</b>

        <a :href="footer.gmapLink" target="_blank" class="footer__links-list-item">{{ footer.address }}</a>

        <div class="footer__links-list-item">
          <p><a :href="`tel:${footer.phoneTollfree}`">{{ $t('footer.toll_free') }}: {{ footer.phoneTollfree }}</a></p>
          <p><a :href="`tel:${footer.phone}`">{{ $t('footer.phone') }}: {{ footer.phone }}</a></p>
        </div>

        <a :href="`mailto:${footer.email}}`" class="footer__links-list-item -email">{{ footer.email }}  <Icon id="arrow"/></a>

        <div class="footer__socials">
          <a :href="footer.facebook" target="_blank"><Icon id="facebook"/></a>
          <a :href="footer.instagram" target="_blank"><Icon id="instagram"/></a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {LangRouterLink} from '@/components/global'

export default {
  name: "SiteNav",
  components: {LangRouterLink},
  inject: ['mq'],
  watch: {
    '$route.name'() {
      this.isOpen = false
    }
  },
  data() {
    return {
      isOpen : false
    }
  },
  computed: {
    langSwitchLabel() {
      return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
    },
    menu() {
      return this.$beet.options.menu || {}
    },
    footer() {
      return this.$beet.options.footer || {}
    }
  },
  methods: {
    goToCms() {
      window.open(process.env.VUE_APP_API_URL.replace('/api', ''), '_self')
    },
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === 'fr' ? 'en' : 'fr'
    }
  }
}
</script>
