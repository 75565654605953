import { createI18n } from 'vue-i18n'
import fr from './fr'
import en from './en'

export default createI18n({
    allowComposition: true,
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: { fr, en }
})
