export default {
  home: 'accueil',
  about: 'a-propos',
  quote: {
    required_fields: 'Les champs possédant un astérisque (*) sont obligatoires.',
    confirm: "Votre demande de soumission a bien été envoyée! Notre équipe l’analysera et entrera en contact avec vous dans les meilleurs délais pour organiser votre voyage!",
    submitAnother: "Soumettre une autre demande",
    questionsTitle: "Des questions?",
    questionsDescription: "Si des questionnements surviennent entre-temps, n’hésitez pas à contacter notre équipe!",
    destination: "Destination",
    selectDestination: "Sélectionner une destination",
    orTxt: 'ou',
    otherDestinationPH: "Préciser une autre destination",
    servicesCenter: "Centre de services scolaire",
    selectSC: "Sélectionner un centre",
    school: "Nom de l’école",
    organizerName: "Nom de l’organisateur",
    organizerEmail: "Adresse courriel de l’organisateur",
    organizerPhone: "Numéro de téléphone de l’organisateur",
    plannedDeparture: "Date de départ",
    plannedReturn: "Date de retour",
    plannedDepartureTime: "Heure de départ",
    plannedReturnTime: "Heure de retour",
    studentsNumber: "Nombre d’étudiants",
    chaperonsNumber: "Nombre d’accompagnateurs",
    withTourGuide: "Présence d’un guide accompagnateur",
    yes: "Oui",
    no: "Non",
    insurances: "Assurances",
    descriptionTitle: "Plus d’information",
    descriptionHelp: "Utilisez cette boîte pour nous donner plus de détails sur votre voyage. Désirez-vous visiter plus d’une ville? Combien de nuit désirez-vous passer dans chacune d’elle? Désirez-vous inclure des repas? Si oui, lesquels? Quelles activités désirez-vous inclure dans votre itinéraire?",
    chooseFile: "Choisir un fichier",
    noFile: "Aucun fichier choisi",
    clientSource: "Où avez-vous entendu parler de nous?",
    submit: "Envoyer la soumission"
  },
  nav: {
    'parent': "Parent",
    'organizers': "Organisateurs"
  },
  footer: {
    'toll_free': "Sans-Frais",
    'phone': "Tél",
    'newsletter_confirm': "Votre inscription à l’infolettre a bien été reçue! Surveillez votre boîte de réception pour les prochaines nouvelles!",
    'newsletterEmail': "Adresse courriel",
    'rights': "Tous droits réservés.",
    'policy': "Politique de confidentialité",
    'devBy': "Site conçu et développé par"
  },
  trips: {
    'type': {
      'plane': 'Avion',
      'bus': 'Autobus',
      'all': 'Toutes les destinations'
    },
    'noResult': 'Aucun résultat ne correspond à votre recherche',
    'noResultHelp': 'Veuillez supprimer ou modifier des filtres ou généraliser votre recherche.',
    'seeMore': "Voir plus de destinations"
  },
  trip: {
    'goBack': 'Retour aux destinations',
    'description': "Description du voyage",
    'destination': "Destination",
    'days': "Jours",
    'meals': "Repas",
    'options': "Personnaliser votre forfait en ajoutant ces activités à votre itinéraire",
    'quote': "Obtenir une soumission en ligne",
    'itinerary': "Itinéraire",
    'othersTitle': "Autres voyages qui peuvent vous intéresser",
    'othersDescription': "Consultez notre collection de voyages pour vous en inspirer. Vous y trouverez des itinéraires que vous pourrez personnaliser selon vos besoins, intérêts et budgets.",
    'othersMore': "Explorer toutes nos destinations"
  }
}
