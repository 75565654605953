import { createStore } from 'vuex'

export default createStore({
  state: {
    filters: {
      filterPlane: false,
      filterBus: false,
      filterZone: 0
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
